
let scriptOrigin;
switch (location.origin) {
  case "https://thailandcommercial.com/":
    scriptOrigin = "https://thaiprop-api-uat.azurewebsites.net";
    break;
  case "https://thailand-property-1.web.app":
    scriptOrigin = "https://thaiprop-api-uat.azurewebsites.net";
    break;
  default:
      
    scriptOrigin = "http://localhost:44336";
    break;
}
document.getElementById("signalrScript").src = scriptOrigin + "/js/signalr/dist/browser/signalr.min.js";

// "use strict";
// ​
// var connection = new signalR.HubConnectionBuilder().withUrl("/channelHub").build();
// ​
// ​
// connection.on("NewNotification", function () {
//     //This method will be call from the backend when there is a new notification
// 	//Implement logic here to increase the number of notifications
// });
// ​
// connection.start().then(function () {
//     //If you want to do anything after the connection is stablished, you can do it here
// }).catch(function (err) {
//     return console.error(err.toString());
// });